body {
    margin: 20px 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    text-align: center;
}

table, thead, th, tr, td {
    border: 1px solid black;
    border-collapse: collapse;
    font-size: 12px;
}

table {
    margin: 0 auto;
}

.leyendas {
    background-color: #bbbbbb;
}

.totales {
    background-color: gray;
    text-align: right;
}

.fuentes-notas {
    font-size: 10px;
}

ol {
    list-style-position: inside;
}

@media (max-width: 400px) {
    table, thead, th, tr, td {
        font-size: 8px;
    }

    table {
        margin: 0 14px;
    }
}